import cartActionTypes from './cart.types';
import { addItemToCart, removeItemToCart } from './cart.utils';

const INITITAL_STATE = {
    hidden: true,
    cartItems: []
};


const cartReducer = (state = INITITAL_STATE, action) => {

    switch (action.type) {
        case cartActionTypes.TOGGLE_CART_HIDDEN:
           return {
             ...state,
             hidden: !state.hidden
           };
        case cartActionTypes.ADD_ITEM:
            return{
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            };

        case cartActionTypes.REMOVE_ITEM:
            return{
                ...state,
                cartItems: removeItemToCart(state.cartItems, action.payload)
            };

        case cartActionTypes.CLEAR_ITEM_FROM_CART:
            return{
                ...state,
                cartItems: state.cartItems.filter(cartItem => cartItem.id !== action.payload.id)
            };
    
        default:
            return state;
    }
}


export default cartReducer;