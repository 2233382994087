import React from 'react'
import Directory from '../../components/directory/directory.components';

import './homePage.style.scss';




const HomePage = () => {
  return (
    <div className='homepage'>
        <Directory/>
    </div>

  )
}

export default HomePage